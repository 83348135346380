@import '../abstracts/abstracts';

/*********************************
    SECTION TALENT
*********************************/
.talent {
  background-color: $color-white;
  padding: 0 0 3rem 0;
  @include respond(tab-port) {
    padding: 6rem 0;
  }
  & .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include respond(tab-port) {
      flex-direction: row;
      align-items: flex-start;
    }
  }
  &__left {
    margin-bottom: 3rem;
    @include respond(tab-port) {
      margin-bottom: 0;
      padding-right: 6rem;
    }
    img {
      max-width: 100%;
      height: 30rem;
      @include respond(tab-port) {
        height: auto;
      }
    }
  }
  &__right {
    flex-basis: 50%;
    padding-top: 2rem;
  }
  &__img {
    max-width: 100%;
    height: auto;
    align-self: center;
    @include box-shadow(10px, 5px, 30px, -10px, $color-black);
    @include respond(tab-port) {
      max-width: unset;
    }
  }
  .heading-secondary {
    @include respond(tab-port) {
      
    }
  }
}
/*********************************
    SECTION COSTA RICA
*********************************/
.costa_rica {
  background-color: $color-tertiary;
  padding: 3rem 0;
  @include respond(tab-port) {
    padding: 6rem 0;
  }
  & .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include respond(tab-port) {
      flex-direction: row;
      align-items: flex-start;
    }
  }
  &__left {
    flex-basis: 100%;
    margin-bottom: 3rem;
    @include respond(tab-port) {
      margin-bottom: 0;
      flex-basis: 50%;
      padding-right: 6rem;
    }
    @include respond(big-desktop) {
      flex-basis: 60%;
    }
  }
  &__right {
    flex-basis: 100%;
    @include respond(tab-port) {
      margin-bottom: 0;
      flex-basis: 50%;
      align-self: flex-end;
    }
    @include respond(big-desktop) {
      flex-basis: 40%;
    }
    img {
      max-width: 100%;
      height: 15rem;
      @include respond(tab-port) {
        height: auto;
      }
    }
  }
  &__img {
    max-width: 100%;
    height: auto;
    align-self: center;
    @include respond(tab-port) {
      max-width: unset;
    }
  }
}
/*********************************
    SECTION HERE
*********************************/
.here {
  position: relative;
  width: 100%;
  padding: 6rem 2rem 6rem 0;
  background-image: url('../../img/group-of-people-sitting-inside-room.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  @include respond(tab-port) {
    padding-right: 6rem;
  }
  &::before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 80%;
    z-index: 0;
    @include linear-gradient(
      $color-black,
      270deg,
      rgba(0, 0, 0, 0.7) 20%,
      rgba(0, 0, 0, 0) 100%
    );
    @include respond(tab-port) {
      @include linear-gradient(
        $color-black,
        270deg,
        rgba(0, 0, 0, 1) 40%,
        rgba(0, 0, 0, 0.75) 60%,
        rgba(0, 0, 0, 0.5) 75%,
        rgba(0, 0, 0, 0.25) 90%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }
  & .container {
    display: flex;
    justify-content: center;
    padding-right: 0;
    @include respond(tab-port) {
      justify-content: flex-end;
    }
  }
  &__content {
    width: 80%;
    z-index: 1;
    @include respond(tab-port) {
      width: 46%;
    }
  }
  &__paragraph {
    color: $color-white;
  }
  &__list {
    list-style: none;
  }
}

/*********************************
    SECTION CLIENTS
*********************************/
.clients {
  background-color: $color-black-light-1;
  padding: 6rem 2rem 6rem 0;
  &__paragraph {
    color: $color-white;
    text-align: center;
    @include respond(tab-port) {
      padding-left: 4rem;
      text-align: left;
    }
  }
  &__logos {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    &__top,
    &__bottom {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }
    &__top {
      margin-bottom: 4rem;
    }
  }
  &__img {
    max-width: 60%;
    height: auto;
    @include respond(tab-port) {
      max-width: 20%;
      padding: 0 20px;
    }
  }
}
/*********************************
    HOME - SLIDER
*********************************/
.home__slider {
  width: 100%;
  position: relative;
  height: auto;
  min-height: 100vh;
  &::before {
    content: '';
    width: 100%;
    height: 8rem;
    position: absolute;
    top: 5rem;
    left: 0;
    @include linear-gradient(
      $color-black,
      180deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0.5) 50%,
      rgba(0, 0, 0, 0) 100%
    );
    z-index: 2;
    @include respond(tab-port) {
      top: 0;
    }
  }
  &::after {
    content: '';
    width: 100%;
    height: 8rem;
    position: absolute;
    bottom: 0;
    left: 0;
    @include linear-gradient(
      $color-black,
      0deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0.5) 50%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  &__logo {
    position: absolute;
    top: 9rem;
    left: 2rem;
    z-index: 3;
    @include respond(phone) {
      top: 6rem;
    }
    @include respond(tab-port) {
      top: 4rem;
      left: 4rem;
    }
    img {
      display: none;
      @include respond(tab-port) {
        max-width: 100%;
        height: auto;
        display: block;
      }
    }
  }
  .heading-secondary {
    font-size: 10vw;
    font-weight: 900;
    text-transform: uppercase;
    @include respond(phone) {
      font-size: 8vw;
    }
    @include respond(tab-port) {
      font-size: 6rem;
    }
    @include respond(tab-port) {
      font-size: 9rem;
    }
  }
  .paragraph {
    font-weight: 500;
    font-size: 4rem;
    @include respond(tab-port) {
      font-size: 5rem;
    }
  }
  .slider {
    &__img {
      width: 100%;
      height: 100vh;
      padding: 4rem 2rem 0rem 2rem;
      &--1,
      &--2 {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        @include respond(tab-port) {
          background-position: top left;
        }
      }
      &--1 {
        background-image: url('../../img/photo-of-people-having-fist-bump.jpg');
      }
      &--2 {
        background-image: url('../../img/close-up-photography-of-man-wearing-sunglasses.jpg');
      }
      &--3 {
        position: relative;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        h2,
        p {
          z-index: 1;
        }
      }
      @include respond(tab-port) {
        padding: 4rem 0 11rem 4rem;
      }
      @include respond(tab-land) {
        padding: 4rem 0 8rem 4rem;
      }
    }
    &__video {
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .slick-dots {
      left: 2rem;
      right: unset;
      z-index: 3;
      li {
        margin: 0 0.2rem;
      }
      @include respond(tab-port) {
        left: 4rem;
      }
    }
    .slick-slide {
      flex-direction: column;
      justify-content: center;
      @include respond(tab-port) {
        justify-content: flex-end;
      }
    }
  }
}
